import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import DangerBox1 from 'components/Software/Linux/Shinobi_Open_Source_CCTV/DangerBox1';
import DangerBox2 from 'components/Software/Linux/Shinobi_Open_Source_CCTV/DangerBox2';
import DetectorTable from 'components/Software/Linux/Shinobi_Open_Source_CCTV/DetectorTable';
import MotionTable from 'components/Software/Linux/Shinobi_Open_Source_CCTV/MotionTable';
import RecTable from 'components/Software/Linux/Shinobi_Open_Source_CCTV/RecTable';
import NavButtons from 'components/Software/Linux/NavButtons';
import ForumBox from 'components/Software/Linux/Shinobi_Open_Source_CCTV/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Shinobi Open Source CCTV",
  "path": "/Software/Linux/Shinobi_Open_Source_CCTV/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "Shinobi is Open Source, written in Node.js, and real easy to use. It is the future of CCTV and NVR for developers and end-users alike. Compatible with the IP camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./P_SearchThumb_Linux.png",
  "social": "/images/Search/P_SearchThumb_Linux.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Linux_Shinobi_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Shinobi"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Shinobi Open Source CCTV' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Shinobi is Open Source, written in Node.js, and real easy to use. It is the future of CCTV and NVR for developers and end-users alike. Compatible with the IP camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/P_SearchThumb_Linux.png' twitter='/images/Search/P_SearchThumb_Linux.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Linux/Shinobi_Open_Source_CCTV/' locationFR='/fr/Software/Linux/Shinobi_Open_Source_CCTV/' crumbLabel="Shinobi" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#linux",
        "aria-label": "linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LINUX`}</h2>
    <h3 {...{
      "id": "shinobi-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#shinobi-installation",
        "aria-label": "shinobi installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Shinobi Installation`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#shinobi-installation"
        }}>{`Shinobi Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#shinobi-configuration"
        }}>{`Shinobi Configuration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-an-instar-full-hd-camera"
        }}>{`Adding an INSTAR Full HD Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setting-up-motion-detection-for-your-instar-ip-camera"
        }}>{`Setting up Motion Detection for your INSTAR IP Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#default-motion-detection-settings"
        }}>{`Default Motion Detection Settings`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#global-detector-settings-primary-engine--pixel-array"
            }}>{`Global Detector Settings Primary Engine : Pixel Array`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#motion-detection-primary-engine--pixel-array"
            }}>{`Motion Detection Primary Engine : Pixel Array`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#traditional-recording"
            }}>{`Traditional Recording`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p><a parentName="p" {...{
        "href": "https://shinobi.video"
      }}>{`Shinobi`}</a>{` is Open Source, written in Node.js, and real easy to use. It is the future of CCTV and NVR for developers and end-users alike. It is catered to by professionals and most importantly by the one who created it.`}</p>
    <p><img parentName="p" {...{
        "src": "./shinobi.gif",
        "alt": "Shinobi CCTV LINUX Software"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox1 mdxType="DangerBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The easiest way to install, directly on metal, is through git. The following operating systems are supported:`}</p>
    <ul>
      <li parentName="ul">{`Ubuntu 17.10.1`}</li>
      <li parentName="ul">{`CentOS 7`}</li>
      <li parentName="ul">{`MacOS 10.7(+)`}</li>
    </ul>
    <p>{`Become root to use the installer and run Shinobi. Use one of the following to do so.`}</p>
    <p>{`Ubuntu 17.10.1:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span></code></pre></div>
    <p>{`CentOS 7:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span></code></pre></div>
    <p>{`MacOS 10.7(+):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`su`}</span></code></pre></div>
    <p>{`Download and run the installer:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -s https://gitlab.com/Shinobi-Systems/Shinobi-Installer/raw/master/shinobi-install.sh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/5a190/shinobi_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7EAAAOxAGVKw4bAAACAklEQVQ4y42U6ZKbMBCE/QgWkjG4vPEagxE6uHFS2aq8/1t1SgegbAjZH12DcPmjZ3rgUKoGomkhdIu0rEFyjSJT0DcJeRWQdwmZKadCQz4UZK7X64f/LVdQeY1D0vVI2w7JawCrO5CuR8xrJJlC8qyRXgTSpEJ64kgZX+t8PYuVuJwFDkfZ4lg1OIoWpO4Q8Rqk0CCZArkrkJtC9CYRxRzRudoRB0slDpFsEYkGEQ9UNaBXARZXYOcK7MRdjU3dUgUac5wWoFHTWVBkXKrW1aIGTR2Imj95sQ2twIdGVGhEZe3c5Rr0XYFeJehFOEAAZHvApPIOee1cGZeisUBGS9fqJ3fhAzaBZBhssmToQcbBzS/ToG9yaZcxvsAXWMwDIAeNXT2QHyPIa8Dx1wvkYwKZBqefk71vXT9rG5J5wK7Dc+hw8vU1uuu288FotwWFBr1J0OQ/QBtE4WeoWxDvyCb8TS6yLZ/+1XI4w4/JtmXmR/p+qQZuwbxx0K+GYgO4K9j1yfUKMffNzD6tzhZsBQq/NmYHHx4QJnlaE7bu4r9bDYHMhmKCMAGUfmY35Rb7rtz53Z933P2xNnZ+fe8czC3OCs+7wPBNMcl+H906sNK1uKUvzDBOzQyfbm1sq3O7cw2VVMsst+a4AlUHMo1+uUenwa+QfYtG90qK1n/S9lKu8BsEVwatMNbmmAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/e4706/shinobi_01.avif 230w", "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/d1af7/shinobi_01.avif 460w", "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/c9875/shinobi_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/a0b58/shinobi_01.webp 230w", "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/bc10c/shinobi_01.webp 460w", "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/d00b9/shinobi_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/81c8e/shinobi_01.png 230w", "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/08a84/shinobi_01.png 460w", "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/5a190/shinobi_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e0ef3a7d7d9b37ed5f288d3658ae78ae/5a190/shinobi_01.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox2 mdxType="DangerBox2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/145076b1d001cac0a80865fd9c521723/5a190/shinobi_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.782608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAj0lEQVQI132OQQ6CMBREOQK0FRZiJIikhf60AiYsXHsA73+XZwpxpXExmdm8yctUuJHfZyqJTGNgksAyBi6dJ28F1QpFSre3Pg6Y0qIrh/mRTMWJYpkxrXCQiLkKh7Qbv8F1L5x7oSkttbHo0u7wp78OJaJ8RDd+My1coEjW64KWiHuu9K8Hbp0ZTv/t0uEbhhVTrk73KhwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/145076b1d001cac0a80865fd9c521723/e4706/shinobi_00.avif 230w", "/en/static/145076b1d001cac0a80865fd9c521723/d1af7/shinobi_00.avif 460w", "/en/static/145076b1d001cac0a80865fd9c521723/c9875/shinobi_00.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/145076b1d001cac0a80865fd9c521723/a0b58/shinobi_00.webp 230w", "/en/static/145076b1d001cac0a80865fd9c521723/bc10c/shinobi_00.webp 460w", "/en/static/145076b1d001cac0a80865fd9c521723/d00b9/shinobi_00.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/145076b1d001cac0a80865fd9c521723/81c8e/shinobi_00.png 230w", "/en/static/145076b1d001cac0a80865fd9c521723/08a84/shinobi_00.png 460w", "/en/static/145076b1d001cac0a80865fd9c521723/5a190/shinobi_00.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/145076b1d001cac0a80865fd9c521723/5a190/shinobi_00.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "shinobi-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#shinobi-configuration",
        "aria-label": "shinobi configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Shinobi Configuration`}</h2>
    <p>{`Create a Shinobi user by accessing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP Address>:8080/super`}</code>{` and login with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`user: admin@shinobi.video
password: admin`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1baaa8b2098298156b137703254944ba/5a190/shinobi_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACLklEQVQ4y53SzU4TURjG8bkCoIu2M9M2INBiZ6Yz00I/6AdMZNpOiYopia6MK2OiN2BM3BBx4YI1N8BOURKR4sIEwgJpEwK7pmXJdfzNTAGxRY0ufnNOnvOeN+dMjjCdzmAtVJjJFUhlZj2ZbJ6EZhCL3iY6OUV0Isat8eiNxidiTMbiKKrOVFxDmM7Oks4VyOZL3pjK5nmQSvM0PcuTVIbH5gyPFJ14XCOmJJhStAsJYnENRTc9hTnL2y9IkoQoSUgX3LksioQ9EmGpR5Z/1vyJIIriQCj28QdF/IHgX5u5vQRZltE0DcMw0HXdY1wyDLSETjE3w1w+7c2v113VX2ShUAjB/biBaZreeJ1pGqgJA6eUZLHoNu9l/XWuZDJJJBLp/cNisYjjOFQqlQGOU6VYmqPg1lSrv6lxsCyrd8KhoSHW1tbodru0Wi1OT089nU7nytnZmed6dqndbnN+fs7GxgZ+vx9hZGSElZUVms0m+/v7HBwc/BN3z/HxMevr6wQCAYTh4WHerr7h5OSE74eHtJrNm7VuzptHR95h3FsGg0EEn8/Hq9erbO9+ZXNriy+7u2z3a7gaA/nnnR2+7e3x7PkLImNjjI6OIrjvzLaWeVitUbcXqNt2nwXq5XLPwLq7ZnN/fh4rkyEcDiP4JImXpRyNxSrvnTIfa5VfbDplPi3dY6u+xGbVHlj/4JRp3K3xzr5DMBRGkGQZVVEwNA1dVTG0/6CqJBTFezY/ANnrMUO/bBxrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1baaa8b2098298156b137703254944ba/e4706/shinobi_02.avif 230w", "/en/static/1baaa8b2098298156b137703254944ba/d1af7/shinobi_02.avif 460w", "/en/static/1baaa8b2098298156b137703254944ba/c9875/shinobi_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1baaa8b2098298156b137703254944ba/a0b58/shinobi_02.webp 230w", "/en/static/1baaa8b2098298156b137703254944ba/bc10c/shinobi_02.webp 460w", "/en/static/1baaa8b2098298156b137703254944ba/d00b9/shinobi_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1baaa8b2098298156b137703254944ba/81c8e/shinobi_02.png 230w", "/en/static/1baaa8b2098298156b137703254944ba/08a84/shinobi_02.png 460w", "/en/static/1baaa8b2098298156b137703254944ba/5a190/shinobi_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1baaa8b2098298156b137703254944ba/5a190/shinobi_02.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Add User`}</strong>{` and create a default user for your Shinobi installation:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5bddf7c60b553fae868966d7aeb64a84/5a190/shinobi_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.21739130434781%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADe0lEQVQ4y4WS7U9bZRjGzx9gjHPOb2rmghF1I1kCzg+6qTMmUyJ1GYPSUWhHKS2IbB0wxpjABkNMlvkyiaxxMSiOF0WdJo5i12xAV1pKa4nJTObm6KdlLry0pT3n9Gd6Spu5FbyTK/dzTu78cl3PcwsNmzfQsm0jR3OfomXb0xzOeRxL9iN8rH6Tc7VazppLsFZr6K0q5otMMhZhrdnHqcKdWLLXIfTseIbPX8vmzKvPKv2z7Vl0v7QRv/0i85Fl7ty9y+07/yCzdl0bt9OV9wTCjwV5tOdsoG3zetq2rKd9y2M0PrcOn/2iAlmORIhGl5FlmXg8/oAkUVSAkz8NcyjrIYSv9u6g7Y0cTuzKpWtXHl1v5dGycysB52VEOU44FCISCROLxRTo/UoB7SODNG1/AcH9+ivMva3ie1MZvXU6vqwt45RuN9dmPMSB0NJSGpjJoSxJCvDKr7/wkVaF8Efui9ze9DzWrZs4+nIWrXlP8n72o/gcNsQ4ikNRFBWtFdk2+DU1WQ8j9L1nIHDJgdczhc89hX/ag+O3MYLBOWVQkqR0vIwO5eRz+WZmsNtGEc7VVfPnzZvcmAvy161b3JibY9rnJxgMsqTETTxKNOkQMkITFZidZcrrRegxGRkdOI9taAjb0CBjw0MMWa38ff26MiiuRMoEuteh2+lkoLcX4cPSMjrVGjqKS+hQa5TzEdVuAi4XkiyzsLCw6v0pd7jyKPYLP9OY/w7CifJKOnTGtDr1VTQVlzLrmUaSJRYXF9Ox1gI67Q4O79UgHNNVc0yfUg0f6GuwFOv43TOjDIbDYeUeV4ueAronnBwoKkeo19XRoK+jXp/sCVWrK5l2eVgKhQgpCiPG1gZeHXdiKqpAOFDRwEFDAwdXusXQiFFTjc/rTwNSJWeInHJ+dcJFpdqMYKpoxmxoxpzqhmbKNXV43D5iYoz5+QVlfVJOHnAoJ/9PjrvQqmsR9IZW9htaSfX9lW2o99Xj9viJx+X0Dq662FJybcYn3BSVWBC0VZ2UmTpJ9TLTSQq1R5jyBJTBaDSGKEkKNHafEv8i0WVl7tJlF3tKmxD2GLsprPqv3tUfx2a/wuS4g/8ra/wMNWi54LWhKj2OUGA+jcp8moIVJc75hm46Pu3nk7Pf0vfdKP0/jPHNyBj99yjxfX7ETvtAD4eGT9Le00d+RTf/ArmMy484wQ1+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bddf7c60b553fae868966d7aeb64a84/e4706/shinobi_03.avif 230w", "/en/static/5bddf7c60b553fae868966d7aeb64a84/d1af7/shinobi_03.avif 460w", "/en/static/5bddf7c60b553fae868966d7aeb64a84/c9875/shinobi_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5bddf7c60b553fae868966d7aeb64a84/a0b58/shinobi_03.webp 230w", "/en/static/5bddf7c60b553fae868966d7aeb64a84/bc10c/shinobi_03.webp 460w", "/en/static/5bddf7c60b553fae868966d7aeb64a84/d00b9/shinobi_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bddf7c60b553fae868966d7aeb64a84/81c8e/shinobi_03.png 230w", "/en/static/5bddf7c60b553fae868966d7aeb64a84/08a84/shinobi_03.png 460w", "/en/static/5bddf7c60b553fae868966d7aeb64a84/5a190/shinobi_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5bddf7c60b553fae868966d7aeb64a84/5a190/shinobi_03.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now switch to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP Address>:8080/`}</code>{` and login with the default user you just created:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/32abe1cb026104ad74bba21416345fa3/5a190/shinobi_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACd0lEQVQ4y52TTU8TURiF5zcALTOUtpZWiu189GM6/QogFUvplM60SDuyc+MfUDcuXWiMLjAWUUl0pxtNDJZCaYEU0oCEhMrKiDv/hp1jZloITfiILp6cN+977knufXMJvxDEyNg4fEIYHj6oIwhhsC4WQw4nBgcGYRu4ej72QdgdQ7jmYuAccoPgQxEEo8MIRUcghKPwhyKY9gm4K0Rwhw9BYb1wuWg43SycbqYTFwMX64Wb9SIyPKpnECRFgSRJUBR1Ql8bU1upf4DQwi6EImE09sJg7AVFXeIlSRAmkwk0TYNhmDNxu2mEBR+iQb9en+fTMJvNIPr7+8HzPAKBwJl4fTzk8SCy4wF4/ef7AoIAq9WqBZrBcRxYlgHLsidwXEs9Hg4OhwN2u133Hfc7/AwDj8fTCtSuHIvdUMVUWp1MimpSTGkKOTOtzuQU3JrJI5dXkFdm9Vq5PQtJymAymUJSnEJSTEFMpRGPJ2CxWEB0d3WpLxfeYffgJ6q1fZQ3drFZb6i1nUN8axxhr/ELe42jU3Un2rnvP37j46dlaM9H9PR04/HTuWapUv/zeanSLJa31OLaNorlbbVY1vVCllZqqG7tY/H9h3Zgdxeev3ilbu4cNlc2dpvr9QN1vX6AU3opla19zBXetrZsMBjUJ88W8HW5gi9LJayU11Faraql1SouY7m0hs1aHfcePMQVixU2m01bihnXxVk1kRXVm9LEMWhpQlPE5QQm5Em0+om2TpzMxsQYwqPh1lJMxj7E78eQWcxAKkiQ5zuR5tPIvc5BeaMgXUhDnpc75wUJ2cUsph5NwWK2tH8KS4PxMWC8/w/N0fpS/gLfoU7RIDxakwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32abe1cb026104ad74bba21416345fa3/e4706/shinobi_04.avif 230w", "/en/static/32abe1cb026104ad74bba21416345fa3/d1af7/shinobi_04.avif 460w", "/en/static/32abe1cb026104ad74bba21416345fa3/c9875/shinobi_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/32abe1cb026104ad74bba21416345fa3/a0b58/shinobi_04.webp 230w", "/en/static/32abe1cb026104ad74bba21416345fa3/bc10c/shinobi_04.webp 460w", "/en/static/32abe1cb026104ad74bba21416345fa3/d00b9/shinobi_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32abe1cb026104ad74bba21416345fa3/81c8e/shinobi_04.png 230w", "/en/static/32abe1cb026104ad74bba21416345fa3/08a84/shinobi_04.png 460w", "/en/static/32abe1cb026104ad74bba21416345fa3/5a190/shinobi_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/32abe1cb026104ad74bba21416345fa3/5a190/shinobi_04.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-an-instar-full-hd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-an-instar-full-hd-camera",
        "aria-label": "adding an instar full hd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding an INSTAR Full HD Camera`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/670b513f70a1c1c5740795a3e3cab745/5a190/shinobi_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.304347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAiElEQVQI1x2KSw6CMAAFuYcJCwu22ALWGD9gVGqxiYpsWLrwCt5/McYuJi+TN8knFXz7C9N0pyg0VV2z1Bpr13FFliOVQmuDKSvqlSXLcxZSxV8pFT02piR5zFLezYbeHxFzgVQFe6sYby0hBK7O4b3HnRuG4ck4vqL/6Q4VoTV0W0m3k/TuxA/zEED4Tue6KgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/670b513f70a1c1c5740795a3e3cab745/e4706/shinobi_05.avif 230w", "/en/static/670b513f70a1c1c5740795a3e3cab745/d1af7/shinobi_05.avif 460w", "/en/static/670b513f70a1c1c5740795a3e3cab745/c9875/shinobi_05.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/670b513f70a1c1c5740795a3e3cab745/a0b58/shinobi_05.webp 230w", "/en/static/670b513f70a1c1c5740795a3e3cab745/bc10c/shinobi_05.webp 460w", "/en/static/670b513f70a1c1c5740795a3e3cab745/d00b9/shinobi_05.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/670b513f70a1c1c5740795a3e3cab745/81c8e/shinobi_05.png 230w", "/en/static/670b513f70a1c1c5740795a3e3cab745/08a84/shinobi_05.png 460w", "/en/static/670b513f70a1c1c5740795a3e3cab745/5a190/shinobi_05.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/670b513f70a1c1c5740795a3e3cab745/5a190/shinobi_05.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can start using Shinobi by clicking on the `}<strong parentName="p">{`+`}</strong>{` Symbol in the top left and adding an ID and a readable Name for the camera we want to add in the `}<strong parentName="p">{`Identity`}</strong>{` section. It is important to choose the `}<strong parentName="p">{`Watch Only`}</strong>{` mode if you want to use the integrated Motion Detection:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06174b0dc17c288c2300c6d9c7d9646e/5a190/shinobi_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADpElEQVQ4y32U+VIiVxjFfYRknKBgsTX23n17pZtlECIOSBChEscZl3GLmTeYPP45qXsRFSzzx68+tj6cb93a3t6G4sMHNhoN2LZN25JYrNdrrFQqrNfr8juFYejyM1SrVWqNBqrVCgoff+HOx1+xt1fk1s7ODp5gq9XC4XDI0ecxD3o9hmGoiKJIxSAIVBRCQAgh3yNrNuE7Nve1CjzL5FapVKJkd3cXo9GIDw8PuLj4houLC1xdXa1xfX39HK+vrnB5dc3j4QGzdhuDdpeZ1uBWsVhEqVRCoVDgfLHAz5//8vHxEbe3txKuuLmR3KjXd3d3vL+/5/3D35xPDjGZnvCo3YVX3H1xKIVbrTZ9IRAEgmEYMU5ixHGENE3QyjNkWRNps4lABND1fZQrFfzebfKfHz9wmKYU1cp6yv2DPo8nE8xmM0wmE5zMZpjP5zidneLL2Rfc397g7PwM51+/YT47hee6kHW9/P4d/TBgbOprDtnpdJgkCaWTJE6QZRmSNJVNkQ8ijmO4rgPd0Knr+2oKXM/DdHbC+cmEvU5rKSjF9vb24HkedV1HtVJlrVajadps5V2kSU7fDek5gcJ1BFwngPAj2I7D7sEnnH/9i4s/T18EZQ2lg+l0yv6gjySJ4Tguu+0e8qwDzw2wFFJIUfpeANM0mXfa7HTbdF1n3aFt2+oHpmmiXq/DMEz6fgDH9miZDm3LofUK25bYdFyP+6bB3wqFF0EZwzDC+HjMPM8ht8b3Ax6Pp+i2+/TdiIFIGfjJEymFH9PzfGq6Tj8QlPXeSDnh5eWFGuw/JhM09hssl8tqzeT6ySaskDVuNDS6rsuyXE9NY6Vafe2wiCiKOR59xng8wqDfh6HrrNaqWIrUN6ip3ZaCpWJRjp3SealhqUTP9yGXf/nvDViWRd8TcF2PpmGtYchoWkvBp9GTrASxrGGIwWCg5s92LMrOjo9O2O8NGYmMUbBOKFLKUXvdh9dNge8LHh0dcTT+zCSOoWl1Viplvq3hKmXt2eGmoHLo+0LtbRTHkANu2y6jIIXs5hu8mL4XvuvwWbDT6aiUZS1N01KD7Ng+N5GDreI7DpcpCyGPJzVNUx10HA/tVo+trMs866q4QjrMmzmF8FksvuMwCEMMh0PVmF7vEwzDoGXacGyXmxi6yTBQ13s1x+sOV8chjCLESQLDNCDXq9PuQR0FeRxeIY/F/9VQpRyEIeUtXCwWyPNMzhqaaYurY7CJ54o3gv8B6vKggfMTwcAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06174b0dc17c288c2300c6d9c7d9646e/e4706/shinobi_06.avif 230w", "/en/static/06174b0dc17c288c2300c6d9c7d9646e/d1af7/shinobi_06.avif 460w", "/en/static/06174b0dc17c288c2300c6d9c7d9646e/c9875/shinobi_06.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06174b0dc17c288c2300c6d9c7d9646e/a0b58/shinobi_06.webp 230w", "/en/static/06174b0dc17c288c2300c6d9c7d9646e/bc10c/shinobi_06.webp 460w", "/en/static/06174b0dc17c288c2300c6d9c7d9646e/d00b9/shinobi_06.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06174b0dc17c288c2300c6d9c7d9646e/81c8e/shinobi_06.png 230w", "/en/static/06174b0dc17c288c2300c6d9c7d9646e/08a84/shinobi_06.png 460w", "/en/static/06174b0dc17c288c2300c6d9c7d9646e/5a190/shinobi_06.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06174b0dc17c288c2300c6d9c7d9646e/5a190/shinobi_06.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For the `}<strong parentName="p">{`Connection`}</strong>{` part we need to choose `}<em parentName="p">{`h.264`}</em>{` and the `}<a parentName="p" {...{
        "href": "/en/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
      }}>{`Video (RTSP) Path`}</a>{` for our camera model (`}<strong parentName="p">{`Note`}</strong>{`: that we are choosing the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`11`}</code>{` path that represents the full resolution. If you are experiencing issues - like a stuttering video stream or high CPU loads - you can switch to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`12`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`13`}</code>{` instead to set a lower resolution):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`rtsp://`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Camera IP Address`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`/11`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/93de33c5e08d7079c806162657b56cab/5a190/shinobi_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADl0lEQVQ4y32TyW7bVhiF8wpFgXgMSc0WZ1EDJZLiqMGSTVmiJTt16jhB3BbdFyj6HE27KtBdX6LDoouu+k5fQdqGo6TJ4uDwv8A9PPfcc5/s7+9zcLDPZ58/5bvXM/797Qf+/vV7/vr5G/54+4Y/397e4aev7vlu/v3HW/755Vsu5x5N3WI09GlXqzzJBXM83dnlOLT5+nLKTZZwk0W8WkW8ymJuspjrs4CbVcTLVXjHy5AvFx5O1yCZzEgcF+XZ4aPg7u4ultXGD2MGrs8wHDH0Ezw/xhlG2I6P7Qb0PR93GDLwfPafSaiqyus3tyR9G71S3ha0ux3Wmw3LbEl6mpKdrznPlqzXazbrjOUiJcsyXry4xnP7SKKAaehcXl0RdDtYcnNbsNtps7m4JDs/L5AuFiwWKYvTlNnsmOVySZqmbDYXTKYTFEVB1xTmp3Oy1ZIo8LcFe90OJ+kJs9mMyXjM6XzOIk05nk4ZjRNO5idMpmNG4zGzJEBu1LAtjfEo4Hx9zipbvXfkXpfjJCB2O8ROm8hpE3s2E9/GMg0Mw0DXdVRNQzmqUa1U6LUUwsCj07Mpld/JcGdnB2dgoygq9mDA5uKCdJFi93rFmmVZ6JpGq9Wi0agjShKCVMLp6limTqVe5/DwcFtw0LfRNA3P91muViRJgj8c4roOQRiiyDIHBwdIkoQkiTwTRIZ9C1U5Qla1oiVbR+7bPey+Ta9nE4YBw2BIEie4rkcUJ3ieR6tloRtG4ViWZXRVRhAEBEHcdviQ4dnZGfP5CWdnC04Xp0wnU6IgJHa7jPw+ydBmFIeEYchRo4Gq3LnO9+/t7T0K5oPVMnEdt3DoDT1M00QUBUoliZIkFL3LuVwuFWuiKKKpSiGY7891njyI5Zz/zWq3i37lOdVrR0TBmNBPCvbcEF1rY+gdzBxGB11TPxR8EM1bn3cpfw2O51Kr1tBUo4CuGSiyRqPe3ML/Cj4McrNRBF2vN4qgq9UqtVq14Ifver1WoHaP/KV81KGmKURxRL9v02w2C3HXdYsy56V+H3nGnzxyyzT44uqKm5fXPL98XmRakiQq5XKB8jsole74kw4NQyeK4uKmB4M+pUqFPVFgXxQLHEjifanvil0qldA/dcuK3KTZPCrcHAoCZq3BiWExMy3mhsVYNVFkvYAq68iy9jGHe48ZRhHj0QjVMLEbTW69iGvH56Xjc2HZGKqFkVdHa6OprQ8y/A8/b302b3XnZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/93de33c5e08d7079c806162657b56cab/e4706/shinobi_07.avif 230w", "/en/static/93de33c5e08d7079c806162657b56cab/d1af7/shinobi_07.avif 460w", "/en/static/93de33c5e08d7079c806162657b56cab/c9875/shinobi_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/93de33c5e08d7079c806162657b56cab/a0b58/shinobi_07.webp 230w", "/en/static/93de33c5e08d7079c806162657b56cab/bc10c/shinobi_07.webp 460w", "/en/static/93de33c5e08d7079c806162657b56cab/d00b9/shinobi_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/93de33c5e08d7079c806162657b56cab/81c8e/shinobi_07.png 230w", "/en/static/93de33c5e08d7079c806162657b56cab/08a84/shinobi_07.png 460w", "/en/static/93de33c5e08d7079c806162657b56cab/5a190/shinobi_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/93de33c5e08d7079c806162657b56cab/5a190/shinobi_07.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now close the configuration menu to save your settings. Open the side menu by clicking on the hamburger icon in the top left and click on the camera you just added. The video stream should show up in a floating viewport on the right (might take a few seconds - if it takes too long, consider using a smaller RTSP stream as stated above):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cb8de2d83394a3438dae908e44e4205a/5a190/shinobi_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB8ElEQVQoz23R60tTARjH8f0fWQbTuZ1znOc23WmXQ7syxy46725uBlENd9NyK1thibaQbBARQZhiJQXdXgjay+jv+sYZFBi9ePi9eODD7+GxBQMBAv4AsWiUYUlCdDoRXS4kUSDoG8Xr9eLxeDCMMUzTRNM0ZNmNLgso4gCq7O7tzWAQSRKx+YwgY54rKCM6E+MJ6jM5xnWdTDLN3us3NDcfU16p0LrT5PrcPIXSNQq5KeLhMKpiYTqKoqKqKoqiYKvXqiwvlxgaGCCVGmd6LodPkZlJptje3WPtbpPV26s0bt0kk0zx/MUrahOThPx+RhQFTdV6rS3QSls4EiFomjgdDhKxMLOFWfyaQi4e4+zXT/Y/HfHlxwk7O1vkC0ucnp5RjISIxyKMyPI5zEqbY3CQIYcDweXC9HmZnE4z6hbIhEJ8/PqN3e4zOrsd7m2sky8V2T88oPPwPtlsmmG3G13Xe9ifsYmCgDUWOKbKZBJRFqayNGplui+77H844u3xe46/f2ZlrcFW5wkH7w5ZWFxEEsX/g0LvqyJXw2HyhTzlapnmgyYbj9psbLZpd7Y5qa+yslQgOz+Pqql4DePvqedAC+u1dAnY7XYu9l1AkA0Sk1GKNxZprbeoNeo8rVSppFNc7uvjUn8/kiT12v2L/gbQ2DAwMRUVjAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb8de2d83394a3438dae908e44e4205a/e4706/shinobi_08.avif 230w", "/en/static/cb8de2d83394a3438dae908e44e4205a/d1af7/shinobi_08.avif 460w", "/en/static/cb8de2d83394a3438dae908e44e4205a/c9875/shinobi_08.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cb8de2d83394a3438dae908e44e4205a/a0b58/shinobi_08.webp 230w", "/en/static/cb8de2d83394a3438dae908e44e4205a/bc10c/shinobi_08.webp 460w", "/en/static/cb8de2d83394a3438dae908e44e4205a/d00b9/shinobi_08.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb8de2d83394a3438dae908e44e4205a/81c8e/shinobi_08.png 230w", "/en/static/cb8de2d83394a3438dae908e44e4205a/08a84/shinobi_08.png 460w", "/en/static/cb8de2d83394a3438dae908e44e4205a/5a190/shinobi_08.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cb8de2d83394a3438dae908e44e4205a/5a190/shinobi_08.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "setting-up-motion-detection-for-your-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-motion-detection-for-your-instar-ip-camera",
        "aria-label": "setting up motion detection for your instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up Motion Detection for your INSTAR IP Camera`}</h2>
    <p>{`Now click on the `}<strong parentName="p">{`Wrench Button`}</strong>{` to get back to the configuration menu and switch the menu to `}<strong parentName="p">{`Advanced Mode`}</strong>{` at the bottom of the floating window:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8cf161f0425a118615810f04bc37a43e/5a190/shinobi_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.173913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAm0lEQVQI1z3HvQqCUACAUWeV7GLqjTBozC6CQn9QbSXRIAVRYQSS/dDQ0tYQ9QI9RC/6NQQNZzhalm15PR+s8wudtKC3OBMnG/rpnu78RDs9oOIuKmjSUopQKS4qpAhCkt2NWX4nmi6ZXMcM1kM00zDQdR1hlXDEj2uXqQjrf891kVLieB6+lHyqdd61Bv5oRSs54kcJtilwhMMXxApAZId18K0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8cf161f0425a118615810f04bc37a43e/e4706/shinobi_09.avif 230w", "/en/static/8cf161f0425a118615810f04bc37a43e/d1af7/shinobi_09.avif 460w", "/en/static/8cf161f0425a118615810f04bc37a43e/c9875/shinobi_09.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8cf161f0425a118615810f04bc37a43e/a0b58/shinobi_09.webp 230w", "/en/static/8cf161f0425a118615810f04bc37a43e/bc10c/shinobi_09.webp 460w", "/en/static/8cf161f0425a118615810f04bc37a43e/d00b9/shinobi_09.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8cf161f0425a118615810f04bc37a43e/81c8e/shinobi_09.png 230w", "/en/static/8cf161f0425a118615810f04bc37a43e/08a84/shinobi_09.png 460w", "/en/static/8cf161f0425a118615810f04bc37a43e/5a190/shinobi_09.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8cf161f0425a118615810f04bc37a43e/5a190/shinobi_09.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The menu will now offer a couple of additional configuration option - a couple of the relate to alarm features:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/5a190/shinobi_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "109.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEAElEQVQ4y32TWW8TVxiG50dUaqUEO/HY49nPmc37viWx4yTYYCdpSNKaAAlrW/Wil1RQECpc9B+0ogKqSv2JTzVToEDTXDx6P83ynu+c7z1KvV5nOByytbVFsVRCSInn+Xi+jysErpAI6SGE/Lc+B1d6BEGI0u/3qddquNLn3uEmf/1yn9fP7/L659u8eXGPV89Oefn4K17+9DW/Pz3h5eNjfn24/xG//XjAH0+PuLE7QJlOp1yZTpPVtwc17h+OON1f587BkNsHG5x9ucHZ/hqne4NEb+32uTXvcbY34HS3n+jxTp3F5RrDTgnl6PCQvb09Ou02pVKJVneNdm+dTn+DeqtHo91L6mZnQK3ZpdHu0+wOku+qjQ66JfhiKc1nny9hmCbKeGeH6WzG5OpVKtUypqGjaTlyuSxaLoue1zANA8s00PU8phnXJno+j6HnyayukFVVclkV4Toox5MJV/oDWlJS9ST1RgMhBI7jJD87tqBabuHaAdKNEE6YENcxnigk+LKAJwXKw2uH3Dk65vp0yuEHE+/1elQqFbLZLKqaJZfNkc3mEv2Q+H2C+rbDG+sbnFyesBhvsRkESVw8zyOdXmFlZQUnb1AQAW7eQhg2Qj8fadgEseGV2YzFYsF4a5swCtnc3GQ0HNJqtvClxNV0fBFi6w6O6eIY/8U2XFzDIXScf6a8O5+zvb1NrVLGNgyk4yS6mkohtTxFW+BpBr5m4if6CXkTV83TrwQo4/GYtbW1ZBCecOl1OnRaLSzDYCV1idC0aRSrSMPFN8X5WBKpOzQjD2V/f5/5fE6pVE4MC1FEsVBACoGaWSUwLKp+Ed9wCUzxv0SWIIi3HEURvu8jpSQKA3Z2drg6m3F5MmE97ty00E2HvG5diJ43ieKhxGZBECSmhTBgPBolW+7HsSmVUJeXk62vplOspi+dQyohk7pEYFso78xila6DnsuhZbOklpdJLS3h5w2KliQy3AsQRLpDGHcYG8XE2StEIfPdXSbTKe12m3q9hjQsTNPFNJyL0S1Cx/64w3goxTDEexsbxzSxMhmsjPqJfoj6/rn/zvC9qe/Ris+v30/IqBmk8KlXO8n9fXdvz0O6YZIMJQxDkkkHQRKZbq9HtVZLKJXL5DSNdDqNqmbIZC5AzSCEg2IYBpqm4dom0tIQto50LDzHJLX0OVY+R6lQxTIFju19hG2JBMeUmKqDa9ooi8V1vv/uW0azI4qb11g/uMtgdsLo6AHR8IBo44BypUXgRYRBkTAovKdUqFEuNCiWq7TnXaqjKsrz5y/4880rbvzwjM7NJ3RvPqE4+4bezUf0zp7RPHmEVyjj+xI/8JOzjjVORbPZodscUOnWmL+YMXww4m+xE9yCEm52wgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/e4706/shinobi_10.avif 230w", "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/d1af7/shinobi_10.avif 460w", "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/c9875/shinobi_10.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/a0b58/shinobi_10.webp 230w", "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/bc10c/shinobi_10.webp 460w", "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/d00b9/shinobi_10.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/81c8e/shinobi_10.png 230w", "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/08a84/shinobi_10.png 460w", "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/5a190/shinobi_10.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8867cf2fcf75f427e0ee8aac6a2f7c2a/5a190/shinobi_10.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Global Detector Settings`}</strong>{`: It is best to leave everything at it's `}<a parentName="li" {...{
          "href": "#default-motion-detection-settings"
        }}>{`default values`}</a>{` - you can come back to it later to add some performance fine tuning here.`}</li>
      <li parentName="ul"><strong parentName="li">{`No Motion Detector`}</strong>{`: An interesting feature is the inverted motion detection - do you want to make sure that your machine is working at all time? Set up Shinobi to notify you when it stopped.`}</li>
      <li parentName="ul"><strong parentName="li">{`Audio Detector`}</strong>{`: Our RTSP stream contains both the video as well as the audio stream from our camera. Use the audio to trigger a recording in Shinobi.`}</li>
      <li parentName="ul"><strong parentName="li">{`Object Detection`}</strong>{`: This function requires the `}<a parentName="li" {...{
          "href": "https://shinobi.video/docs/object"
        }}>{`additional installation`}</a>{` of `}<a parentName="li" {...{
          "href": "https://opencv.org"
        }}>{`OpenCV`}</a>{` and is out of the scope of this tutorial.`}</li>
    </ul>
    <p>{`And last but not least, we have the classic `}<strong parentName="p">{`Motion Detection`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/5a190/shinobi_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "131.30434782608694%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEPUlEQVRIx32VyXLbRhCG+RApH5xYABfswAxmAQhSACVTciiSEU2HMi1K1s1VrsotrxInch4kr5JXySEEulMNQottMYeveoBC/ezln2br6uoK3r9/D2maIgsDCH2vCn2/6rbbYLx4gYHjYioSYB5D7hO8ibtzHDwgOMPWZrOB9XoNr179iEoKWCzOy9l8Xp6cnlaDwaAKggiUzDAIYghDgWHQ8PgcCIxCgTHn2FosFvD27dtaNNEKBoMMiqKA0WgEJycnled5YJomdrtd6HY7FL+iedfpIGfRTvD09BQ453WGeZ7/myTJVmu9jaKw9L0Q8+ExZP0c8uERKtFHHmmM2WMS5JHaZUg9vLm5gYuLCxgVOViWBYZh4B227WDgR0DC+4nQ9wLk1MN3797Bx48f4fr6GoaDDM7Pf9quVqvtdDrdKqWqKGSoRAY80vBlVnsyfP36NdBg5vM5xJxR+TQMiKKI2gC9XhdM08B22wSzbeI+2m1j10MSXCzOYTabQZEfglKyUkqB1roKAh8818dEDYCyEHwfKQqmkTOGLfLg5eUlaJ2AiDlcXW3K9XpdbjabcjKZlLbtgON4aFsu2LaL+7B6DqYqxtabN29guVxCv99HKrnI863v+5Vt2+C6bh07nTbZAzq1RZ6ii4Zp4jjX2BJCIPWKImV4dDSqRqNRSRRFsWUshsPBMVkF6tKeKpnt3isZY4v61UBTIkEYj8e1uRljYPUsIOvYlg22ZeNTWE1kNBQSklI2ggxEHFdCiPpHbNsCx3bJEkC2+D9ipqjCbwVjzisyt2maZPDdlPUA6K6ySAKLJD4Fj+TOh3eCUiqQIsbpdFqNx+Mqz3MYDocQBBEKrjHwGYQ+x70EjBJ6IsM4BnomH5K5Pc+HMGDgOj56rg+u6+NTUCXfCNKUp2dn5Ww2r+bzeRmGAbiOB2TsMIiRhbIu/Vsksrv19XWGnLN6KI7jQLMcgC6+Y7skjs5XuI8iv5uyoh6SsIjh7GxSzmaz7cuX41InuvL9ELI0Rx4qkEzD7ortWRKUoZQSlaKhyHofrlarcnVxUS2Xy2oymVRt+iswDtAwDfiubeJz00BaFkbD/dk4eMjwcQ+zrA+0bZQkH9q1sS3bRWY58KvZxVnXQtN20CGzP8ayG0F5L0jLAWUcVzyKqlRr8BwHIyp5eAwngUD8vge/2RGacYoJ0ygbBEtQcb0ztnwkyDjDXhBUvTCE550OPDs4QNNy4CCU+EvHg3+eGfjXD130/Bh7QYxWw92Z3dmmzk4pGEqBN8NBdTMcwErEsGQhrqWEy/4QPwxz/JtJvNV9/DnN8DrJcJM+cJVmeChFMxQpkUmJEyXg92KAfxSH+Pkoxz+PC6jjywJvz6f46XyGn+Zn+Pno8J7bo923t6MhzrTEVpZl2M/6kGUZpP0UI62gRkkCI91EKZA1RFp9iVLItEKdJPgfUjQ9iWInPV0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1fe97099b4e6d89647ee70cd0ce5004/e4706/shinobi_11.avif 230w", "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/d1af7/shinobi_11.avif 460w", "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/c9875/shinobi_11.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1fe97099b4e6d89647ee70cd0ce5004/a0b58/shinobi_11.webp 230w", "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/bc10c/shinobi_11.webp 460w", "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/d00b9/shinobi_11.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1fe97099b4e6d89647ee70cd0ce5004/81c8e/shinobi_11.png 230w", "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/08a84/shinobi_11.png 460w", "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/5a190/shinobi_11.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1fe97099b4e6d89647ee70cd0ce5004/5a190/shinobi_11.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Correction`}</strong>{`: the screenshot above set's the `}<strong parentName="p">{`Recording Timeout`}</strong>{` to 5min - it is much more sensible to choose a value of 0.2, resulting in 12s alarm videos.`}</p>
    <p>{`Here, again, we will leave most at the `}<a parentName="p" {...{
        "href": "#default-motion-detection-settings"
      }}>{`default settings`}</a>{` and move on the `}<strong parentName="p">{`Region Editor`}</strong>{` that allows us to define `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Motion Detection Areas`}</a>{` that will be used to limit the area that will be watched by the detection algorithm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d4450e987df626883778cffc6de45916/5a190/shinobi_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADQElEQVQ4y32UW09cZRSG9x9RCVOkM7PP59l79pwPm5mhMIcKhZZAh3IK0Ci0aIvEFskUa7T1kFCJhkNrY9ToXeOV197WH/SY2cS2JsSLJ2tlJd/6st43awnlUpGRsEI+l8W2TFzbwnkD1zajuq7rmKaJ7TjohoGkqqiGgWqa6JaFYZpoqorw8XKTk73rPLk7wXfbExxuT/6Hb+9fYXu2TsrzCMOQwPe5PDrG5uwci80ma+0ON9ptmpcuRU0F19YxNRkpPkTadQjcFCnTwrNsPMsi5TjYqsLoaJ1WZxwjkWB9vMOL929x2GrzbKxFb2yc7vwiS90bCJIkkUiKvPX2O2iajWX6xOMqyaROMqEhJnSScZFGrRo1TOkay+EIP83McNhp8azdZCVIs7p1l083txA0VUGSRAYGBiKNLMtCFEVkSY6Q+ohJwnKRzkQLSxZZH23w98PP+PPDDV7u3mM1rHJwfMqDW5sIiiKjKAqDsUHEpIwiG8iihiy9iUwpl+Ha3BSuobBar/PX7h6nSwscLs7TbY9zf3+fR709BEWWUFWN4eF3SSYkVNlElvTXiDqKrKKLcTxDJp+y6F65TG/3Ho+++ZrDp8c8/OoLFpaXODo5QpD648kysViMZKI/pnHWRDJeoSoalUqZtbUVdj65w5PjA05/fc7Jz8/58bdf+P7pMSs3b9JdWECwTCPSrW+Oqqpoms7F4YtciF1gaGiIwcEYiUSCsVaHrTvb9B702H/8Ob3HX/LBR7f5/Y8XHBz9wNTMHNVaDcFzU2TSeTw3wHPTZIMcfirAMVMYhksjnWEyk8bNZJmdusbc5DR+Lk+51qBSa7C+scnE9FVcL001HEHIBlmq5TrFXIVivkotHCWsNCgXQrLZCt1mh6X6CPFoAiUyUNO0KO9vhigm6Rvb36S+dEImHZw9ThfIBUXKhQqFTIl8UMT381wN68xXSoiqimWamIaBYRiY5r/RjPJ+7H8kpFyPbKaE72VJ+znyuRJBOk/g53DcDO+FNaaLBRKygq4bkcbnouuRB4Jn67xGw3P0V/jOWd13DTKeQ+DZ/0t0HPrXppDLUCkXKRXzuI51Pvb5pFwbVZG4vbnBzs4O/wDbQyQ44BtPKAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4450e987df626883778cffc6de45916/e4706/shinobi_12.avif 230w", "/en/static/d4450e987df626883778cffc6de45916/d1af7/shinobi_12.avif 460w", "/en/static/d4450e987df626883778cffc6de45916/c9875/shinobi_12.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d4450e987df626883778cffc6de45916/a0b58/shinobi_12.webp 230w", "/en/static/d4450e987df626883778cffc6de45916/bc10c/shinobi_12.webp 460w", "/en/static/d4450e987df626883778cffc6de45916/d00b9/shinobi_12.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4450e987df626883778cffc6de45916/81c8e/shinobi_12.png 230w", "/en/static/d4450e987df626883778cffc6de45916/08a84/shinobi_12.png 460w", "/en/static/d4450e987df626883778cffc6de45916/5a190/shinobi_12.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d4450e987df626883778cffc6de45916/5a190/shinobi_12.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Klick on the green `}<strong parentName="p">{`+ Button`}</strong>{` to add an area - the new area will be displayed in the top left corner of the camera video image. You can drag&drop each of the white anchor points to reposition them. Or click on the red line to add a new anchor (a right-click deletes a point). Klick on `}<strong parentName="p">{`Close`}</strong>{` to save your settings.`}</p>
    <p>{`When you scroll further down the config menu you will reach the rest of the motion detection settings - again, we will leave most of them at their `}<a parentName="p" {...{
        "href": "#default-motion-detection-settings"
      }}>{`default settings`}</a>{` for now (to be further fine-tuned later on):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/276a0f712d31bd270cf8c80cc91dfd24/5a190/shinobi_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "122.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADNUlEQVQ4y5WUy2ojRxSG+z0kuS8aSd1V1V19b1s3e3TxFduybGzJEvbCswtMdskiJJs8woRAcCAEQuYhZkhmAskmzzT/CVXWOEMgkrz4OA1d/Jw69f/HuP9mTn+//pr++OkL+uuXr/Dnz1/S+/uXeHf/kn774TN69+PnpL7ffndHb1/dPdT/8ObVHf3+/Qv69dtrMrKQYTPxNZGoU8RrSKRLrTxCv9uiTpFSp8hWsr2VUzOTZMggwGC4h95giDBOkGYFVUwLnhdQpz0kX2QUiHwp6owMCmIeJyPPEtzc3ODq6grz2Qyz2QxZmsK2LbIsE45tk/pehrOobv0ZGZy56HS7aLfb1Ol00Gw24XkeOY4Dx3E+rStpLARJyhCu66JcLlO5XCFVfRFSlrQQyYLicB02yeeCjCyNMZ1OP4zHYxwcHODo6Ai93nNwJoizgJgnwDxBq/GJeS4ZRZ5iPp9jMpnoOU6nUxRFoa4JyzLJsi1YlprnEuyHqq8cCE67u7vY2dnB9vY2Dg8PEYahfpBqdf35/TtDzwXnAlJK1Ot1lEolVCplcOYjkjmFQYYwyGglMifBtG1SzGZz3N7eQs3x9PQUw+EAzOMQTBJn8mP9Xx7/e54ytsBgMKCtrS1ijFEURVA4jk2mWVFXJ3MF+oy5QfValQzmNRAEATjn8H0fpmnSxsYGBA+g7CD9BGGQ0nISkkFKgjEyNosM19czXFxc4PLyUj9MlmVKUB2GzyMKRET+Eh7+x8SVYJrEdH5+jtHoFGdnZ5p2u62ssr5trE9skyaR7kw9iBJWnQ6HQ1Sr1SdFz7aVbWpkZEmsDa2ERqMRTk5OcHx8jEajoTt7smAchRiPz3WXHxkMBrBt+0mL4VEwlD71+32otLRaLf3SyuBKUM3Rtm2sWl961dmLGTK3Adfz4PtCX7NUKmlPBb5EEinbxCS1LVaxsE2aRKQWwmQy+aDq/v4+XLehokdxmC9sE6+FFgwEx97eHnq9HilUStSCNU1TZVqnYB0ek+K5db0chBA6MbVaTSeFMYEib5Lgci2UwXWHoQzQbLb06u92u0iSBPV6jZI4Q//5PuVpay2KvENxGNE/ycn7e4o4iPQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/276a0f712d31bd270cf8c80cc91dfd24/e4706/shinobi_13.avif 230w", "/en/static/276a0f712d31bd270cf8c80cc91dfd24/d1af7/shinobi_13.avif 460w", "/en/static/276a0f712d31bd270cf8c80cc91dfd24/c9875/shinobi_13.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/276a0f712d31bd270cf8c80cc91dfd24/a0b58/shinobi_13.webp 230w", "/en/static/276a0f712d31bd270cf8c80cc91dfd24/bc10c/shinobi_13.webp 460w", "/en/static/276a0f712d31bd270cf8c80cc91dfd24/d00b9/shinobi_13.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/276a0f712d31bd270cf8c80cc91dfd24/81c8e/shinobi_13.png 230w", "/en/static/276a0f712d31bd270cf8c80cc91dfd24/08a84/shinobi_13.png 460w", "/en/static/276a0f712d31bd270cf8c80cc91dfd24/5a190/shinobi_13.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/276a0f712d31bd270cf8c80cc91dfd24/5a190/shinobi_13.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Close the configuration menu now and walk in front of your camera to trigger a few alarm recordings. The Shinobi offers a couple of ways to view our alarm videos:`}</p>
    <ul>
      <li parentName="ul">{`Event Log`}</li>
      <li parentName="ul">{`Video Grid`}</li>
      <li parentName="ul">{`Video List`}</li>
      <li parentName="ul">{`Time Lapse Recordings`}</li>
      <li parentName="ul">{`Power Viewer`}</li>
    </ul>
    <p>{`We are going to show you the `}<strong parentName="p">{`Power Viewer`}</strong>{` as seen in the screenshots below. Click on the button marked with the red arrow:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "658px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/78741e4c80d16af61a8715b9562a6b79/889a4/shinobi_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvUlEQVQI12OISUoJjY4uq60srSmrba5p7G5p7++ZOGdm7+yp0ztbu/u7J8yfM3HB/J6ZMyfPn+/q5VNS3ZBdUMzFzWNlY8egqqqmoqKqpqauqqqqpWdoampqaKpnZmlmYmU13cwqyc3dxNra2srKytra2sLCSEtLSVlZWUVFSUlJUVGRAUIpKikpSEtouSeUFZek1rg1z+1cs3z16kWLNq5bt3jx4mVLlqxYu3ZFb0+ptaWsgoKSEsgEDQ0NAKfqPkc+dHP6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/78741e4c80d16af61a8715b9562a6b79/e4706/shinobi_14.avif 230w", "/en/static/78741e4c80d16af61a8715b9562a6b79/d1af7/shinobi_14.avif 460w", "/en/static/78741e4c80d16af61a8715b9562a6b79/09bf4/shinobi_14.avif 658w"],
              "sizes": "(max-width: 658px) 100vw, 658px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/78741e4c80d16af61a8715b9562a6b79/a0b58/shinobi_14.webp 230w", "/en/static/78741e4c80d16af61a8715b9562a6b79/bc10c/shinobi_14.webp 460w", "/en/static/78741e4c80d16af61a8715b9562a6b79/6ad61/shinobi_14.webp 658w"],
              "sizes": "(max-width: 658px) 100vw, 658px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/78741e4c80d16af61a8715b9562a6b79/81c8e/shinobi_14.png 230w", "/en/static/78741e4c80d16af61a8715b9562a6b79/08a84/shinobi_14.png 460w", "/en/static/78741e4c80d16af61a8715b9562a6b79/889a4/shinobi_14.png 658w"],
              "sizes": "(max-width: 658px) 100vw, 658px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/78741e4c80d16af61a8715b9562a6b79/889a4/shinobi_14.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Power Viewer will show you a timeline of all alarm recording events - the higher the red bars, the more motion was detected inside the video. Clicking on a bar in the timeline opens the recorded video.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/5a190/shinobi_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADXklEQVQ4y51UW28bRRjd30JfqmQvszOzs5fZXXvX99iOHTtunIRgkgingTahbQJVriLNRamIgqiQkBCgiHJ74YF/xT9Ags5B47RVn3n4dL4ZnZn5vvMdjVEsFlGr1ZBlRXicg1IKz/PeovAFKHXBGAPnfBL0nVxzGNPnbveMOIlRyjKsjFYx/uQB1j4YodvpII4l0jRBb7aL9fGmWn5/BcPBPVUtVxSjVEkpEYWxqlYaiIIEgkfwuIBRn6mhnMZ49OkO9k6eYXP0odra2ES3UlFSCtWQCY4vr7A+/kgdPj3A/cUluI6NJElULFOU8hqyYhnlUh2+CGDUG1WUU4mj/T28/OM37H62i4uDI1VPYiQ+QasQqbPLC3VyeYq9gwO1tbnxN+f8VZIkCPxI3VYWwvckuK4wz1KkPsXR/j6+f3mDJ7uP1dfXV1iY76nBcPjv9uNtfLy9pU6fn2P88KFqdjp/BUHwj5YjjQtKhil87/ZSzgQMGfgY9mdxdvEFbn7/GVcvvlLHz06wtLykKHVf+UEIyyHK0UEIXNeFbdswp6eVtMhkIFOmiffu3MG0acLozPXx5fUVrr99gW9++A4//voLevcWVJaXwKIcebmqZlszmG030Wq2VbvdVo7jKMe00CJCVR0GPmVCTtsQDoGRpgUM+wMkxQzd/jzmh4uqUMyRFFLlzwzU+MkRnh6dqtH9HbVz+Aij1ZEiDlHaVj7jKuJCIwRjYC6FIYSAaZkgxIFtWbBME7ZjoygkFmsDdNo9NDpzaJRmMNdqoFjK4RIC6rqwiIO7jgWbEBCXwKUUhp4W595b4wrPw5RLsJpWcNNcRpkJ3DWnJg+alg3bcSZch1LkIsRyXIL0BFxdIWMwoiickF2X3CIhmHYsPEhr+Km1gsPSLC4qPUjGwamLgFIw95bzedbEn501NPwIpmOBuS6MaqmKXqWDPKmimDdQyRpoFOq4mB/jrLuGjeo8jufW0MybWCx3sNZcgEzKqBXqeD7YwGV3He1CHQv1OeRpBiMMQsgwhuf5CKMYYSghvABeKEGED1vLEUbaxBAiQCBjeCKY5DySsD0B7vkoJAVEkYShfaXb1R+ARr3WuRad6fYoneRv9t9w3+XoiU+8qX3IXov5f0MP0rIs9Pt9nJ+f4z9AixwxQ/5g4QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/e4706/shinobi_15.avif 230w", "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/d1af7/shinobi_15.avif 460w", "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/c9875/shinobi_15.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/a0b58/shinobi_15.webp 230w", "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/bc10c/shinobi_15.webp 460w", "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/d00b9/shinobi_15.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/81c8e/shinobi_15.png 230w", "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/08a84/shinobi_15.png 460w", "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/5a190/shinobi_15.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2bdb464cf5ae1b2a7c7b4a39b698295/5a190/shinobi_15.png",
              "alt": "Shinobi CCTV",
              "title": "Shinobi CCTV",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "default-motion-detection-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#default-motion-detection-settings",
        "aria-label": "default motion detection settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Default Motion Detection Settings`}</h2>
    <h3 {...{
      "id": "global-detector-settings-primary-engine--pixel-array",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#global-detector-settings-primary-engine--pixel-array",
        "aria-label": "global detector settings primary engine  pixel array permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Global Detector Settings Primary Engine : Pixel Array`}</h3>
    <DetectorTable mdxType="DetectorTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "motion-detection-primary-engine--pixel-array",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motion-detection-primary-engine--pixel-array",
        "aria-label": "motion detection primary engine  pixel array permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection Primary Engine : Pixel Array`}</h3>
    <MotionTable mdxType="MotionTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "traditional-recording",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#traditional-recording",
        "aria-label": "traditional recording permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Traditional Recording`}</h3>
    <RecTable mdxType="RecTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      